import { useEffect } from "react";
import { VerticalBar } from "../../components";
import UserComponent from "./UserComponent";

function UserPage() {
  useEffect(() => {
    document.title = "PEMOL | User";
  }, []);

  return (
    <VerticalBar>
      <UserComponent />
    </VerticalBar>
  );
}

export default UserPage;
