import React from "react";
import { Transaction } from "../../assets";

interface CardProps {
  title: string;
  value?: number | string;
}

function index({ title, value }: CardProps) {
  return (
    <div className="w-full h-40 border-gray-primary border-2 rounded-lg md:p-4 shadow">
      <img src={Transaction} alt="transaction-icon" height={36} width={36} />
      <div className="mt-6">
        <h6 className="font-medium text-base text-gray-100">{title}</h6>
        <h5 className="font-bold text-2xl text-black">
          {value?.toLocaleString("en-US")}
        </h5>
      </div>
    </div>
  );
}

export default index;
