import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { TopBar, VerticalBar } from "./components";
import { DashboardPage, LoginPage, MitraPage, UserPage } from "./pages";
import { RoutesPage } from "./routes";

function App() {
  return (
    <>
      <Routes>
        <Route path={RoutesPage.Login} element={<LoginPage />} />
      </Routes>

      <TopBar>
        <Routes>
          <Route path={RoutesPage.Dashboard} element={<DashboardPage />} />
          <Route path={RoutesPage.User} element={<UserPage />} />
          <Route path={RoutesPage.Mitra} element={<MitraPage />} />
        </Routes>
      </TopBar>
    </>
  );
}

export default App;
