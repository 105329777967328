import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { AiOutlineMail, AiOutlineKey } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../assets";
import { RoutesPage } from "../../routes";
import { LoginProps } from "./LoginTypes";

interface LoginFormProps {
  email: string;
  password: string;
}

function LoginPage() {
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState<LoginFormProps>({
    email: "",
    password: "",
  });

  useEffect(() => {
    document.title = "PEMOL | LOGIN";
  }, []);

  const handleDisabled = () => {
    if (
      loginForm.email === null ||
      loginForm.email === "" ||
      loginForm.password === null ||
      loginForm.password === ""
    ) {
      return true;
    }

    return false;
  };

  const handleOnChange = (value: string, targetObject: string) => {
    setLoginForm((prevState) => {
      return {
        ...prevState,
        [targetObject]: value,
      };
    });
  };

  const handleLogin = () => {
    axios
      .post<LoginProps>(`${process.env.REACT_APP_URL}/login`, loginForm)
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("name", res.data.user.nama);
        navigate(RoutesPage.Dashboard, { replace: true });
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <div className="flex justify-center items-center h-screen">
        <div className="flex flex-col">
          <img src={Logo} alt="alt-logo" className="-mt-20 mb-20" />
          <h3 className="text-black font-bold text-xl mb-10">
            Login Page PEMOL Dev POS
          </h3>
          <div className="flex items-center p-2 border-2 border-black rounded-full">
            <AiOutlineMail size={20} className="mr-4" />
            <input
              type="text"
              placeholder="Email"
              name="email"
              onChange={(e) => handleOnChange(e.currentTarget.value, "email")}
              className="border-0 focus:outline-none"
            />
          </div>
          <div className="flex items-center p-2 border-2 border-black rounded-full mt-5">
            <AiOutlineKey size={20} className="mr-4" />
            <input
              type="password"
              placeholder="Password"
              onChange={(e) =>
                handleOnChange(e.currentTarget.value, "password")
              }
              className="border-0 focus:outline-none"
            />
          </div>
          <div className="mt-5 ml-auto">
            <button
              type="button"
              disabled={handleDisabled()}
              onClick={handleLogin}
              className="bg-green-600 text-white hover:bg-green-900 hover:text-warmGray-300 transform duration-300 rounded-full px-6 py-2 disabled:bg-slate-500 disabled:text-white"
            >
              <h6>Login</h6>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
