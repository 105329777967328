const userIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="current"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 9.60002C0 5.07453 0 2.81179 1.40589 1.4059C2.81177 1.52588e-05 5.07452 1.52588e-05 9.6 1.52588e-05H10.4C14.9255 1.52588e-05 17.1882 1.52588e-05 18.5941 1.4059C20 2.81179 20 5.07453 20 9.60002V10.4C20 14.9255 20 17.1882 18.5941 18.5941C17.1882 20 14.9255 20 10.4 20H9.6C5.07452 20 2.81177 20 1.40589 18.5941C0 17.1882 0 14.9255 0 10.4V9.60002ZM5.61689 14.2879C6.87433 13.323 8.41501 12.8 9.99998 12.8C11.5849 12.8 13.1256 13.323 14.3831 14.2879C15.6405 15.2527 16.5444 16.6055 16.9546 18.1365C17.1262 18.7767 16.7463 19.4347 16.1061 19.6062C15.466 19.7777 14.8079 19.3978 14.6364 18.7577C14.3629 17.737 13.7603 16.8352 12.922 16.1919C12.0837 15.5487 11.0566 15.2 9.99998 15.2C8.94333 15.2 7.91621 15.5487 7.07792 16.1919C6.23963 16.8352 5.63701 17.737 5.36353 18.7577C5.192 19.3978 4.534 19.7777 3.89384 19.6062C3.25368 19.4347 2.87378 18.7767 3.04531 18.1365C3.45553 16.6055 4.35946 15.2527 5.61689 14.2879ZM8.19998 7.00001C8.19998 6.0059 9.00586 5.20001 9.99998 5.20001C10.9941 5.20001 11.8 6.0059 11.8 7.00001C11.8 7.99412 10.9941 8.80001 9.99998 8.80001C9.00586 8.80001 8.19998 7.99412 8.19998 7.00001ZM9.99998 2.80001C7.68038 2.80001 5.79998 4.68041 5.79998 7.00001C5.79998 9.3196 7.68038 11.2 9.99998 11.2C12.3196 11.2 14.2 9.3196 14.2 7.00001C14.2 4.68041 12.3196 2.80001 9.99998 2.80001Z"
      fill="current"
    />
  </svg>
);

export default userIcon;
