import axios from "axios";
import React, { useEffect, useState } from "react";
import { Badge, Card, Filter } from "../../components";

interface DashboardDataProps {
  total_transaction: number;
  total_transaction_failed: number;
  total_transaction_success: number;
  total_waste_all: number;
  total_waste_all_failed: number;
  total_waste_all_success: number;
}

function Dashboard() {
  const token = localStorage.getItem("token");
  const [dashboardDataSuccess, setDashboardDataSuccess] =
    useState<DashboardDataProps>();
  const [dashboardDataFailed, setDashboardDataFailed] =
    useState<DashboardDataProps>();
  const [dashboardDataAll, setDashboardDataAll] =
    useState<DashboardDataProps>();
  const [irisBalance, setIrisBalance] = useState<string>();
  const [mobileBalance, setMobileBalance] = useState<number>();
  const [newUserTransaction, setNewUserTransaction] = useState<number>();

  const date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  const firstDay = new Date(y, m, 1);
  const lastDay = new Date(y, m + 1, 0);

  useEffect(() => {
    document.title = "PEMOL | Dashboard";
  }, []);

  const getDashboardDataSuccess = () => {
    axios
      .get(`${process.env.REACT_APP_URL}/transaksi/dashboard-history-success`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setDashboardDataSuccess(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getDashboardDataAll = () => {
    axios
      .get(`${process.env.REACT_APP_URL}/transaksi/dashboard-history-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setDashboardDataAll(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getDashboardDataFailed = () => {
    axios
      .get(`${process.env.REACT_APP_URL}/transaksi/dashboard-history-failed`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setDashboardDataFailed(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getIrisBalance = () => {
    axios
      .get(`${process.env.REACT_APP_URL}/iris/checkBalance`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const balance = res.data.balance.toString().split(".");
        const convertBalance1 = parseInt(balance[0]).toLocaleString("en-US");
        setIrisBalance(`${convertBalance1}.${balance[1]}`);
      })
      .catch((err) => console.log(err));
  };

  const getMobileBalance = () => {
    axios
      .get(`${process.env.REACT_APP_URL}/info/balance-mpl`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMobileBalance(res.data.data.balance);
      })
      .catch((err) => console.log(err));
  };

  const getNewUserTransaction = () => {
    axios
      .get(`${process.env.REACT_APP_URL}/info/getUserAll`, {
        params: {
          startDate: firstDay,
          endDate: lastDay,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setNewUserTransaction(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDashboardDataSuccess();
  }, []);

  useEffect(() => {
    getDashboardDataAll();
  }, []);

  useEffect(() => {
    getDashboardDataFailed();
  }, []);

  useEffect(() => {
    getIrisBalance();
  }, []);

  useEffect(() => {
    getMobileBalance();
  }, []);

  useEffect(() => {
    getNewUserTransaction();
  }, []);

  return (
    <div className="mt-11 px-6">
      <div className="flex items-center justify-between mb-7">
        <div className="flex items-center">
          <h5 className="font-bold text-black text-xl">Realtime Activity</h5>
          <div className="ml-3">
            <Badge title="This Month" color="secondary" />
          </div>
        </div>

        <Filter />
      </div>

      <div className="grid grid-cols-6 gap-4">
        <Card
          title="Total Transaction"
          value={dashboardDataAll?.total_transaction}
        />

        <Card
          title="Transaction Success"
          value={dashboardDataSuccess?.total_transaction_success}
        />

        <Card
          title="Transaction Failed"
          value={dashboardDataFailed?.total_transaction_failed}
        />

        <Card
          title="Total Waste (in Kg)"
          value={dashboardDataAll?.total_waste_all}
        />

        <Card
          title="Total Success (in Kg)"
          value={dashboardDataSuccess?.total_waste_all_success}
        />

        <Card
          title="Total Failed (in Kg)"
          value={dashboardDataFailed?.total_waste_all_failed}
        />

        <Card title="Iris Balance" value={`Rp. ${irisBalance}`} />

        <Card
          title="Mobile Pulsa Balance"
          value={`Rp. ${mobileBalance?.toLocaleString("en-US")}`}
        />

        <Card title="New User Transaction" value={newUserTransaction} />
      </div>
    </div>
  );
}

export default Dashboard;
