import { MitraIcon, UserIcon } from "../../assets";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RoutesPage } from "../../routes";

function VerticalBar({ children }: any) {
  const [location, setLocation] = useState("");
  const [hover, setHover] = useState("");
  const pathName = window.location.href;
  const navigate = useNavigate();

  useEffect(() => {
    if (pathName.indexOf("user") > -1) {
      setLocation("user");
    }

    if (pathName.indexOf("mitra") > -1) {
      setLocation("mitra");
    }
  }, [pathName]);
  return (
    <div
      className={`flex ${
        location === "mitra" || location === "user" ? "visible" : "hidden"
      }`}
    >
      <div className="w-60 relative border-gray-200 border-r-2">
        {/* Start of pengguna menu */}
        <div
          className="flex flex-row items-center cursor-pointer"
          onMouseEnter={() => {
            setHover("user");
          }}
          onMouseLeave={() => {
            setHover("");
          }}
          onClick={() => {
            navigate(RoutesPage.User);
          }}
        >
          <div
            className={`w-2 h-9 rounded-full transform duration-300 transition ${
              location === "user" || hover === "user"
                ? "bg-red-100"
                : "bg-transparent"
            }`}
          ></div>
          <div
            className={`flex bg-opacity-5 m-3 py-4 px-3 rounded-lg w-full transform duration-300 ${
              (location === "user" || hover === "user") && "bg-red-100"
            }`}
          >
            <UserIcon
              className={`h-6 w-6 mr-3 transform duration-300 ${
                location === "user" || hover === "user"
                  ? "fill-red-100"
                  : "fill-gray-100"
              }`}
            />
            <h6
              className={`transform duration-300 font-bold ${
                location === "user" || hover === "user"
                  ? "text-red-100"
                  : "text-gray-100"
              }`}
            >
              Pengguna
            </h6>
          </div>
        </div>
        {/* End of pengguna menu */}

        {/* Start of mitra menu */}
        <div
          className="flex flex-row items-center cursor-pointer"
          onMouseEnter={() => {
            setHover("mitra");
          }}
          onMouseLeave={() => {
            setHover("");
          }}
          onClick={() => {
            navigate(RoutesPage.Mitra);
          }}
        >
          <div
            className={`w-2 h-9 rounded-full transform duration-300 ${
              location === "mitra" || hover === "mitra"
                ? "bg-red-100"
                : "bg-transparent"
            }`}
          ></div>
          <div
            className={`flex bg-opacity-5 m-3 py-4 px-3 rounded-lg w-full transform duration-300 ${
              (location === "mitra" || hover === "mitra") && "bg-red-100"
            }`}
          >
            <MitraIcon
              className={`h-6 w-6 mr-3 transform duration-300 ${
                location === "mitra" || hover === "mitra"
                  ? "fill-red-100"
                  : "fill-gray-100"
              }`}
            />
            <h6
              className={`transform duration-300 ${
                location === "mitra" || hover === "mitra"
                  ? "text-red-100 font-bold"
                  : "text-gray-100"
              }`}
            >
              Mitra
            </h6>
          </div>
        </div>
        {/* End of mitra menu */}
      </div>
      <div className="width-menu">{children}</div>
    </div>
  );
}

export default VerticalBar;
