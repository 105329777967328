const mitraIcon = (props) => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.0098 9.09303C4.73646 8.89779 4.59979 8.80017 4.46414 8.78814C4.34516 8.7776 4.22634 8.80999 4.12918 8.87946C4.0184 8.95867 3.95019 9.11215 3.81376 9.4191L0.775206 16.2559L0.775202 16.2559C0.53166 16.8038 0.409889 17.0778 0.461746 17.2541C0.50675 17.4071 0.622022 17.5294 0.772055 17.5834C0.944937 17.6457 1.22568 17.5404 1.78715 17.3298L7.71913 15.1053C7.82308 15.0664 7.87505 15.0469 7.92852 15.0391C7.97595 15.0323 8.02411 15.0323 8.07154 15.0391C8.12501 15.0469 8.17698 15.0664 8.28093 15.1053L14.2129 17.3298C14.7744 17.5404 15.0551 17.6457 15.228 17.5834C15.378 17.5294 15.4933 17.4071 15.5383 17.2541C15.5902 17.0778 15.4684 16.8038 15.2249 16.2559L12.1863 9.4191C12.0499 9.11215 11.9817 8.95867 11.8709 8.87946C11.7737 8.80999 11.6549 8.7776 11.5359 8.78814C11.4003 8.80017 11.2636 8.89779 10.9903 9.09303L8.58127 10.8137C8.23357 11.0621 7.76649 11.0621 7.41879 10.8137L5.0098 9.09303ZM5.06115 6.61248C5.04948 6.63873 5.05789 6.66957 5.08127 6.68627V6.68627L7.53504 8.43896C7.70235 8.55847 7.786 8.61822 7.87725 8.64134C7.95783 8.66175 8.04223 8.66175 8.12281 8.64134C8.21405 8.61822 8.29771 8.55847 8.46502 8.43896L10.9188 6.68627V6.68627C10.9422 6.66957 10.9506 6.63873 10.9389 6.61248L8.73108 1.64486C8.49955 1.12392 8.38378 0.863443 8.22246 0.783312C8.08234 0.713709 7.91772 0.713709 7.77759 0.783312C7.61628 0.863443 7.50051 1.12392 7.26898 1.64486L5.06115 6.61248Z"
      fill="current"
    />
  </svg>
);

export default mitraIcon;
