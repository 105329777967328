import React, { useEffect } from "react";
import { Link, Routes, useLocation, useNavigate } from "react-router-dom";
import { Logo } from "../../assets";
import { RoutesPage } from "../../routes";

import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    paper: {
      marginRight: theme.spacing(2),
    },
  })
);

function TopBar({ children }: any) {
  const pathName = window.location.href;

  const token = localStorage.getItem("token");
  const name = localStorage.getItem("name");
  const navigate = useNavigate();
  const location = useLocation();

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleLogout = async () => {
    await localStorage.clear();
    window.location.reload();
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (typeof token === "undefined" || token === "" || token == null) {
      navigate(RoutesPage.Login);
    } else if (location.pathname === "/") {
      navigate(RoutesPage.Dashboard);
    } else if (token) {
      if (pathName.indexOf("login") > -1) {
        navigate(RoutesPage.Dashboard);
      }
    }
  }, []);
  return (
    <>
      {token && (
        <div className="shadow flex items-center justify-between bg-white px-9">
          <div className="flex items-center h-20 ">
            <div>
              <img src={Logo} alt="alt-logo" />
            </div>

            <ul className="flex h-full ml-8">
              <li>
                <Link to={RoutesPage.Dashboard}>
                  <button
                    className={`px-6 h-full text-black hover:border-b-2 hover:border-red-200 transform duration-300 ${
                      pathName.indexOf("home") > -1
                        ? "border-b-2 border-red-200 text-opacity-100 font-bold"
                        : "text-opacity-50"
                    }`}
                    // onClick={() => navigate(RoutesPage.Dashboard)}
                  >
                    Dashboard
                  </button>
                </Link>
              </li>
              <li>
                <Link to={RoutesPage.User}>
                  <button
                    className={`px-6 h-full text-black hover:border-b-2 hover:border-red-200 transform duration-300 ${
                      pathName.indexOf("user") > -1 ||
                      pathName.indexOf("mitra") > -1
                        ? "border-b-2 border-red-200 text-opacity-100 font-bold"
                        : "text-opacity-50"
                    }`}
                    // onClick={() => navigate(RoutesPage.User)}
                  >
                    Pengguna
                  </button>
                </Link>
              </li>
              <li>
                <button
                  className={`px-6 h-full text-black hover:border-b-2 hover:border-red-200 transform duration-300 ${
                    pathName.indexOf("transaksi") > -1
                      ? "border-b-2 border-red-200 text-opacity-100"
                      : "text-opacity-50"
                  }`}
                >
                  Transaksi
                </button>
              </li>
              <li>
                <button
                  className={`px-6 h-full text-black hover:border-b-2 hover:border-red-200 transform duration-300 ${
                    pathName.indexOf("produk") > -1
                      ? "border-b-2 border-red-200 text-opacity-100"
                      : "text-opacity-50"
                  }`}
                >
                  Produk
                </button>
              </li>
              <li>
                <button
                  className={`px-6 h-full text-black hover:border-b-2 hover:border-red-200 transform duration-300 ${
                    pathName.indexOf("laporan") > -1
                      ? "border-b-2 border-red-200 text-opacity-100"
                      : "text-opacity-50"
                  }`}
                >
                  Laporan
                </button>
              </li>
            </ul>
          </div>

          <div>
            <Button
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              variant="contained"
              color="primary"
              style={{
                borderRadius: 999,
                backgroundColor: "#FEFAFA",
                border: "1px solid rgba(235, 70, 70, 0.5)",
              }}
            >
              <h6 className="text-black font-bold text-sm">{name}</h6>
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
      )}
      {children}
    </>
  );
}

export default TopBar;
