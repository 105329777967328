import { VerticalBar } from "../../components";
import MitraComponent from "./MitraComponent";

function MitraPage() {
  return (
    <VerticalBar>
      <MitraComponent />
    </VerticalBar>
  );
}

export default MitraPage;
