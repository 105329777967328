import { Pagination } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { SidebarIcon } from "../../assets";
import { UserDataProps } from "../../services/User";

function UserComponent() {
  const token = localStorage.getItem("token");

  // Setting Date FOr Filter Purpose Next
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  if (dd < 10) dd = 0 + dd;
  if (mm < 10) mm = 0 + mm;
  const [dateRange, setDateRange] = useState([
    `${yyyy}-${mm}-01`,
    `${yyyy}-${mm}-${dd}`,
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState<number>();

  const [userList, setUserList] = useState<UserDataProps[]>([]);

  const getUser = () => {
    let query = {
      byuser: [],
      keyword: [],
      status: [1, 2],
      page: 1,
      per_page: 25,
      periode: dateRange,
      sortBy: [],
      sortDesc: true,
    };

    axios
      .get(
        `${process.env.REACT_APP_URL}/user?byUser=[%22Test%22]&status=1,2&page=${currentPage}&per_page=25&sortBy=[%22saldo%22]&sortDesc=[true]`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setUserList(res.data.data.data);
        setMaxPage(res.data.data.last_page);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUser();
  }, [currentPage]);
  return (
    <div className="p-6">
      <h6 className="font-bold text-xl">Data Pengguna</h6>

      <div className="overflow-x-scroll mt-9">
        <table className="table-fixed">
          <thead>
            <tr>
              <div className="sticky left-0 flex">
                <th
                  className="text-sm border-2 border-gray-200 p-4 bg-opacity-5 flex justify-center items-center"
                  style={{ minWidth: "141px", background: "#FEF6F6" }}
                >
                  User Id
                </th>
                <th
                  className="text-sm border-2 border-gray-200 p-4 bg-opacity-5 "
                  style={{
                    minWidth: "227px",
                    maxWidth: "227px",
                    background: "#FEF6F6",
                  }}
                >
                  Nama Lengkap
                </th>
              </div>
              <th
                className="text-sm border-2 border-gray-200 p-4 bg-red-100 bg-opacity-5"
                style={{ minWidth: "170px" }}
              >
                Saldo PemolPay
              </th>
              <th
                className="text-sm border-2 border-gray-200 p-4 bg-red-100 bg-opacity-5"
                style={{ minWidth: "173px" }}
              >
                Nomor Ponsel
              </th>
              <th
                className="text-sm border-2 border-gray-200 p-4 bg-red-100 bg-opacity-5"
                style={{ minWidth: "242px" }}
              >
                Email
              </th>
              <th
                className="text-sm border-2 border-gray-200 p-4 bg-red-100 bg-opacity-5"
                style={{ minWidth: "124px" }}
              >
                Total Order
              </th>
              <th
                className="text-sm border-2 border-gray-200 p-4 bg-red-100 bg-opacity-5"
                style={{ minWidth: "200px" }}
              >
                Order Berhasil
              </th>
            </tr>
          </thead>

          <tbody>
            {userList?.map((user) => {
              return (
                <tr>
                  <div className="sticky left-0 flex">
                    <td
                      className="text-sm border-2 border-gray-200 p-4 bg-white flex flex-row justify-center items-center"
                      style={{ minWidth: "141px", maxWidth: "141px" }}
                    >
                      <p className="p-0 text-sm">{user.id_user}</p>
                    </td>
                    <td
                      className="text-sm border-2 border-gray-200 p-4 bg-white overflow-hidden"
                      style={{ minWidth: "227px", maxWidth: "227px" }}
                    >
                      <div className="flex">
                        <img
                          src={SidebarIcon}
                          alt="alt-icon"
                          className="mr-3"
                        />
                        <p className="p-0 text-sm overflow-ellipsis">
                          {user.name}
                        </p>
                      </div>
                    </td>
                  </div>
                  <td
                    className="text-sm border-2 border-gray-200 p-4 bg-white"
                    style={{ minWidth: "150px" }}
                  >
                    <p className="p-0 text-sm">
                      {Intl.NumberFormat("id-ID", {
                        style: "currency",
                        currency: "IDR",
                      })
                        .format(user.saldo)
                        .replace(/(\.|,)00$/g, "")}
                    </p>
                  </td>
                  <td
                    className="text-sm border-2 border-gray-200 p-4 bg-white"
                    style={{ minWidth: "173px" }}
                  >
                    <p className="p-0 text-sm">{user.no_hp}</p>
                  </td>
                  <td
                    className="text-sm border-2 border-gray-200 p-4 bg-white"
                    style={{ minWidth: "242px" }}
                  >
                    <p className="p-0 text-sm">{user.email}</p>
                  </td>
                  <td
                    className="text-sm border-2 border-gray-200 p-4 bg-white"
                    style={{ minWidth: "124px" }}
                  >
                    <p className="p-0 text-sm">{user.order_completed}</p>
                  </td>
                  <td
                    className="text-sm border-2 border-gray-200 p-4 bg-white"
                    style={{ minWidth: "124px" }}
                  >
                    <p className="p-0 text-sm">{user.order_completed}</p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center items-center mt-5">
        <Pagination
          color="primary"
          count={maxPage}
          onChange={(e, value) => setCurrentPage(value)}
        />
      </div>
    </div>
  );
}

export default UserComponent;
