import React from "react";
import { FilterIcon } from "../../assets";

function index() {
  return (
    <div>
      <div className="flex p-3 rounded-md border-2 border-gray-primary w-max cursor-pointer">
        <img src={FilterIcon} alt="filter-icon" />
        <p className="ml-2 text-xs text-black">This Month</p>
      </div>
    </div>
  );
}

export default index;
