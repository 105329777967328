import React from "react";

interface BadgeProps {
  title: string;
  color: "primary" | "secondary";
}
function index({ title, color = "primary" }: BadgeProps) {
  return (
    <div
      className={`py-1 px-4 rounded-full w-max bg-opacity-5 ${
        color === "primary" ? "bg-black" : "bg-gray-100"
      }`}
    >
      <p
        className={`text-sm font-bold ${
          color === "primary" ? "text-black" : "text-gray-100"
        }`}
      >
        {title}
      </p>
    </div>
  );
}

export default index;
